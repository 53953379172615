import { getFileURL } from 'a4bd-meta';
import { useContext, useEffect, useRef, useState } from 'react';
import { ReactCropperElement } from 'react-cropper';

import { ConfigContext } from '~providers';
import { useUpdateFileByIdMutation } from '~services';

import { cropData, Default, Desktop } from './constants';
import { ImageEditorProps, ImageSizeProps } from './types';
import {
  calcDimensionsForCropper,
  calcDimensionsForServer,
  getCropDataById,
  getDimensionsByType,
  getUpdatedVariants,
} from './utils';

export const useImageEditor = (props: ImageEditorProps) => {
  const { file, imageOptions, onClose, setVariants, variants } = props;

  const [currentCropData, setCurrentCropData] = useState(Default);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [imageSize, setImageSize] = useState<ImageSizeProps>();
  const [updateFileById] = useUpdateFileByIdMutation();

  const cropperRef = useRef<ReactCropperElement>(null);

  useEffect(() => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) cropper.setAspectRatio(Number(currentCropData.ratio));
  }, [currentCropData]);

  const { imageProxyHost } = useContext(ConfigContext);
  const src = getFileURL(file, { ...imageOptions, domain: imageProxyHost });

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const { naturalHeight, naturalWidth } = cropper.getImageData();
      const dimensions = calcDimensionsForServer(cropper);
      const data = getCropDataById(currentCropData.id);
      const currentVariant = { dimensions, type: data?.type };
      setImageSize({ height: naturalHeight, width: naturalWidth });
      setVariants(getUpdatedVariants(variants, data, currentVariant));
    }
  };

  const setCropBoxData = (id: number) => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const { type } = getCropDataById(id);
      const dimensions = getDimensionsByType(variants, type);

      if (dimensions) {
        cropper.setCropBoxData(calcDimensionsForCropper(dimensions, cropper));
      }
    }
  };

  useEffect(() => {
    setCropBoxData(currentCropData.id);
  }, [currentCropData]);

  useEffect(() => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) cropperRef.current.addEventListener('cropend', onCrop);
    return () => cropperRef?.current?.removeEventListener('cropend', onCrop);
  }, [onCrop]);

  const onSizeUp = () => setIsFullscreen(!isFullscreen);

  const onRotateLeft = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) cropper.rotate(90);
  };

  const onRotateRight = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) cropper.rotate(-90);
  };

  const onReset = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) cropper.reset();
  };

  const onFinish = () => {
    if (!imageSize?.height || !imageSize?.width) return null;
    return updateFileById({
      id: file.id,
      meta: { height: imageSize?.height, variants, width: imageSize?.width },
    }).finally(() => onClose());
  };

  const onCropperReady = () => setCurrentCropData(Desktop[0]);

  const isReadyDisabled = !imageSize?.height || !imageSize?.width;

  const aspectRatio = file.meta.width / file.meta.height;

  return {
    aspectRatio,
    cropData,
    cropperRef,
    currentCropData,
    isFullscreen,
    isReadyDisabled,
    onClose,
    onCrop,
    onCropperReady,
    onFinish,
    onReset,
    onRotateLeft,
    onRotateRight,
    onSizeUp,
    setCropBoxData,
    setCurrentCropData,
    src,
  };
};
