import { FileImageVariantType } from './FileImageVariantType';

export const FileImageVariantDefault = {
  [FileImageVariantType.DesktopHead]: {
    height: 425,
    width: 1380,
  },
  [FileImageVariantType.DesktopSlider]: {
    height: 425,
    width: 1026,
  },
  [FileImageVariantType.DesktopWideCard]: {
    height: 425,
    width: 675,
  },
  [FileImageVariantType.DesktopCard]: {
    height: 425,
    width: 322,
  },
  [FileImageVariantType.TabletHead]: {
    height: 340,
    width: 708,
  },
  [FileImageVariantType.TabletWideCard]: {
    height: 310,
    width: 462,
  },
  [FileImageVariantType.TabletCard]: {
    height: 310,
    width: 215,
  },
  [FileImageVariantType.Mobile]: {
    height: 400,
    width: 335,
  },
};
