import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import {
  BlockType,
  CreateUploadBodySecure,
  FileImageVariant,
  FileSecure,
  FileType,
  getFileURL,
  ImageProxyOptions,
} from 'a4bd-meta';
import { Button, ButtonProps, Col, notification, Row, Space, Tooltip } from 'antd';
import classNames from 'classnames';
import { saveAs } from 'file-saver';
import { always, cond, equals, isEmpty, multiply } from 'ramda';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';

import { Audio, Visible } from '~components';
import { DropType, MEGABYTE, SECOND } from '~constants';
import { useIsMobile } from '~hooks';
import { ConfigContext } from '~providers';
import {
  useCreateUploadFileMutation,
  useDeleteFileMutation,
  useGetFileByIdMutation,
  useUploadFileMutation,
  useUploadVideoMutation,
} from '~services';
import { getArrayBuffer, imageProcess, isDefined, isSuccessResult } from '~utils';

import { ImageEditor } from '../ImageEditor';
import { MediaPreview } from './MediaPreview';
import { Preview } from './Preview';
import styles from './styles.module.scss';
import {
  getAccept,
  getErrorMessage,
  getFileName,
  isComplete,
  isError,
  isImage,
  isInProgress,
  isVideo,
} from './utils';

interface Props extends DropzoneOptions {
  blockType?: BlockType;
  buttonsSize?: ButtonProps['size'];
  className?: string;
  imageOptions?: Partial<ImageProxyOptions>;

  onChange?(file?: FileSecure): void;

  onlyIcon?: boolean;
  setImageFile?: (file: any) => void;
  small?: boolean;
  type: DropType;

  updateFileStatus?(file: FileSecure): Promise<FileSecure>;

  uploadProps?: { id: number } & Record<string, string | number>;
  value?: FileSecure;
  withEditor?: boolean;
  wrapperClassName?: string;
}

/**
 * onDrop -> onUploadFile -> createUploadFile -> uploadFile
 */

export const DropZone: React.FC<Props> = (props) => {
  const {
    // buttonsSize = 'large',
    blockType,
    className,
    imageOptions,
    maxFiles = 1,
    maxSize,
    onChange,
    onlyIcon,
    setImageFile,
    small,
    type: dropType,
    updateFileStatus,
    uploadProps,
    value,
    wrapperClassName,
    ...dropProps
  } = props;

  const isMobile = useIsMobile();

  const [isImgEditorShown, setIsImgEditorShown] = useState(false);

  const {
    imageProxyHost,
    upload: { maxSizeAudio, maxSizeDocuments, maxSizeImage, maxSizeVideo, timeFileCheck },
  } = useContext(ConfigContext);
  const defaultCheckStatus = multiply(timeFileCheck, SECOND);

  const getMaxSize = cond<[DropType], number>([
    [equals(DropType.Image), always(multiply(maxSizeImage, MEGABYTE))],
    [equals(DropType.Photo), always(multiply(maxSizeImage, MEGABYTE))],
    [equals(DropType.Gif), always(multiply(maxSizeImage, MEGABYTE))],
    [equals(DropType.Video), always(multiply(maxSizeVideo, MEGABYTE))],
    [equals(DropType.Audio), always(multiply(maxSizeAudio, MEGABYTE))],
    [equals(DropType.Documents), always(multiply(maxSizeDocuments, MEGABYTE))],
  ]);

  const maxSizeFile = maxSize || getMaxSize(dropType);

  const [errorLoading, setErrorLoading] = useState(false);
  const [currentFile, setCurrentFile] = useState(value);
  const [coverVariants, setCoverVariants] = useState<FileImageVariant[]>(
    (currentFile &&
      currentFile.meta &&
      'variants' in currentFile.meta &&
      currentFile.meta.variants) ||
      [],
  );

  const [progress, setProgress] = useState(0);

  const accept = useMemo(() => getAccept(dropType), [dropType]);

  const [createUploadFile] = useCreateUploadFileMutation();
  const [uploadFile] = useUploadFileMutation();
  const [uploadVideo] = useUploadVideoMutation();
  const [deleteFile, { isLoading: isDeletingFile }] = useDeleteFileMutation();
  const [getFileById] = useGetFileByIdMutation();

  const onUploadProgress = (progressEvent: ProgressEvent) =>
    setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));

  const onUploadFile = useCallback(
    async (file: File | Blob) => {
      try {
        setProgress(0);
        setErrorLoading(false);
        const { size: contentLength, type: contentType } = file;
        const uploadData: CreateUploadBodySecure = {
          contentLength,
          contentType,
          relatedTo: blockType ?? dropType,
        };

        if (isImage(dropType)) {
          uploadData.meta = await imageProcess(file);
        }

        const uploadFileData = await createUploadFile(uploadData);

        if (!isSuccessResult(uploadFileData)) {
          throw uploadFileData.error;
        }

        const {
          data: { fileId, uploadUrl },
        } = uploadFileData;

        let uploadStatus;
        if (isVideo(dropType)) {
          const data = new FormData();
          data.append('file', file);
          uploadStatus = await uploadVideo({
            ...uploadProps,
            data,
            onUploadProgress,
            url: uploadUrl,
          });
        } else {
          const data = await getArrayBuffer(file);

          uploadStatus = await uploadFile({
            ...uploadProps,
            contentType,
            data,
            onUploadProgress,
            url: uploadUrl,
          });
        }

        if (!isSuccessResult(uploadStatus)) {
          throw uploadStatus.error;
        }

        const uploadedFile = await getFileById(fileId);

        if (!isSuccessResult(uploadedFile)) {
          throw uploadedFile.error;
        }

        setCurrentFile(uploadedFile.data);
        if (setImageFile) setImageFile(uploadedFile.data);

        setTimeout(async function update() {
          const uploadedFile = await getFileById(fileId);

          if (isSuccessResult(uploadedFile)) {
            const { data: uploadedData } = uploadedFile;

            setCurrentFile(uploadedData);
            if (setImageFile) setImageFile(uploadedData);

            if (isInProgress(uploadedData)) {
              setTimeout(update, defaultCheckStatus);
            }

            if (isDefined(onChange) && isComplete(uploadedData)) {
              onChange(uploadedData);
            }

            if (isError(uploadedData)) {
              setErrorLoading(true);
            }
          }
        }, defaultCheckStatus);
      } catch (e: any) {
        notification.error({
          description: JSON.stringify(e?.message || ''),
          message: `Ошибка загрузки ${e?.code || ''}`,
        });
      } finally {
        setProgress(0);
      }
    },
    [
      blockType,
      createUploadFile,
      defaultCheckStatus,
      dropType,
      getFileById,
      onChange,
      setImageFile,
      uploadFile,
      uploadProps,
      uploadVideo,
    ],
  );

  const onDrop = useCallback(
    // eslint-disable-next-line consistent-return
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (!isEmpty(fileRejections)) {
        fileRejections.forEach((file) => {
          notification.error({
            description: getErrorMessage(file),
            message: (
              <div style={{ display: 'grid' }}>
                <span>Ошибка загрузки файла</span>
                <span>{getFileName(file)}</span>
              </div>
            ),
            placement: 'bottomRight',
          });
        });
      }

      if (isEmpty(acceptedFiles)) {
        return false;
      }

      setProgress(0);

      const [file] = acceptedFiles;
      onUploadFile(file);
    },
    [onUploadFile],
  );

  const onVideoSourceClick = (videoFile: FileSecure) => {
    window.open(videoFile?.url, '_blank', 'noopener noreferrer');
  };

  const { getInputProps, getRootProps, rootRef } = useDropzone({
    accept,
    maxFiles,
    maxSize: maxSizeFile,
    noDragEventsBubbling: true,
    onDrop,
    ...dropProps,
  });

  const onDeleteFile: React.MouseEventHandler<HTMLElement> = useCallback(
    async (event) => {
      if (isDefined(currentFile)) {
        await deleteFile(currentFile.id);
      }
      setCurrentFile(undefined);
      if (setImageFile) setImageFile(undefined);
      setErrorLoading(false);
      if (isDefined(onChange)) {
        onChange(undefined);
      }
      rootRef.current?.focus();
      event.preventDefault();
    },
    [deleteFile, onChange, rootRef, currentFile],
  );

  const onError = () => {
    setErrorLoading(true);
  };

  const DropZonePreview = useCallback(() => {
    if (currentFile && isComplete(currentFile)) {
      return (
        <MediaPreview
          file={currentFile}
          type={dropType}
          onError={onError}
          className={className}
          imageOptions={imageOptions}
        />
      );
    }
    return (
      <Preview
        type={dropType}
        onlyIcon={onlyIcon}
        isSmall={small ?? false}
        isError={errorLoading}
        isProcessing={isDefined(currentFile) && isInProgress(currentFile)}
        isUploading={progress > 0}
        uploadProgress={progress}
      />
    );
  }, [className, dropType, errorLoading, currentFile, imageOptions, progress, small]);

  const isAudio = dropType === DropType.Audio;

  if (isAudio && currentFile && isComplete(currentFile)) {
    const src = getFileURL(currentFile, { ...imageOptions, domain: imageProxyHost });

    return (
      <Row justify="center">
        <Audio src={src} onDelete={onDeleteFile} />
      </Row>
    );
  }

  const onImageEdit = () => setIsImgEditorShown(true);
  const onImageEditClose = () => setIsImgEditorShown(false);

  const getPreviewSize = () => {
    if (imageOptions && imageOptions.width && imageOptions.height) {
      return { height: imageOptions.height, width: imageOptions.width };
    }
    if (isMobile) {
      return {
        height: 'unset',
        maxHeight: '350px',
        width: '100%',
      };
    }
    return { height: '350px', width: '500px' };
  };

  return (
    <>
      <Row justify="center">
        <Col>
          <div
            className={classNames(styles.wrapper, small && styles.wrapperSmall, wrapperClassName)}
          >
            <div style={{ position: 'absolute', right: '8px', top: '8px', zIndex: 1 }}>
              {isDefined(currentFile) && isComplete(currentFile) && !errorLoading && (
                <Space size={12}>
                  <Visible isVisible={currentFile.type === FileType.Video}>
                    <Tooltip title="Перейти к источнику видео">
                      <Button
                        icon={<FileDoneOutlined />}
                        onClick={() => onVideoSourceClick(currentFile)}
                      />
                    </Tooltip>
                  </Visible>
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={() => saveAs(currentFile.url, currentFile.name)}
                  />
                  <Visible
                    isVisible={currentFile.type === FileType.Image && blockType === BlockType.Cover}
                  >
                    <Button icon={<EditOutlined />} onClick={onImageEdit} />
                  </Visible>
                  <Button
                    icon={<DeleteOutlined />}
                    loading={isDeletingFile}
                    onClick={onDeleteFile}
                  />
                </Space>
              )}
            </div>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Row style={getPreviewSize()} className={classNames(styles.previewImageWrapper)}>
                <Col flex="auto" style={getPreviewSize()}>
                  <DropZonePreview />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      {isImgEditorShown && currentFile && (
        <ImageEditor
          file={currentFile}
          variants={coverVariants}
          setVariants={setCoverVariants}
          imageOptions={imageOptions}
          onClose={onImageEditClose}
        />
      )}
    </>
  );
};
