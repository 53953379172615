export const isArrayBuffer = (data: any): data is ArrayBuffer => data instanceof ArrayBuffer;

export const getArrayBuffer = (file: File | Blob): Promise<ArrayBuffer> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      resolve(event.target?.result as ArrayBuffer);
    };

    fileReader.onerror = (event) => {
      reject(event);
    };

    fileReader.readAsArrayBuffer(file);
  });

export const imageProcess = (file: File | Blob): Promise<{ height: number; width: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => resolve({ height: img.height, width: img.width });
    img.onerror = reject;

    img.src = URL.createObjectURL(file);
  });
};
